const data = [
 
  {
    title: "What is Dana’s personal quest?",
    text: "Dana’s personal quest is to reconnect with her birth family from whom she was tragically separated at the age of four due to child trafficking. This journey involves in-depth research, multiple trips to India, and significant resources to piece together her past and find her family.",
  },
 
 
  {
    title: "What additional projects is Dana working on besides the art NFTs?",
    text: "Besides selling art NFTs, Dana is also raising funds to create a documentary and feature films about her background story. These projects aim to depict her traumatic experience of being trafficked as a child and her quest to reunite with her family.",
  },
  {
    title: "What is the purpose of the documentary and feature films?",
    text: "The documentary and feature films aim to raise awareness about the appalling realities of child trafficking, inspire those encountering similar challenges, and offer a unique perspective on resilience, hope, and the indomitable human spirit.",
  },
  {
    title: "What are the benefits of purchasing Dana’s art NFTs?",
    text: " Benefits include exclusive ownership of Dana’s unique artistic expression, supporting her mission to find her birth family, being part of her journey by following her progress, and the potential for future value as her story gains more attention.",
  },
  {
    title: "How can I support Dana in her quest?",
    text: "You can support Dana by purchasing her unique themed art NFTs, spreading the word about her story and NFTs with your network, and following her journey on social media for updates and milestones.",
  },
  {
    title: "What impact does Dana hope to achieve by sharing her story?",
    text: "Dana hopes to shed light on critical issues such as child trafficking, inspire others with her story of resilience and hope, and foster a sense of identity and belonging for herself and those who support her mission.",
  },
];

export default data;
