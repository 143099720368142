import { useState  ,  useRef} from "react";
import { useModal } from "../../../../utils/ModalContext";

import { FaDiscord, FaTwitter, FaWallet } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/logggo.png";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";
import { FaChevronDown } from 'react-icons/fa';

import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  const { walletModalHandle } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };
  

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#roadmap">Roadmap</a>
            </li>
            {/* <li>
              <a href="#team">Team</a>
            </li> */}
             <li ref={dropdownRef}>
                    <button className="dropdown-btn" onClick={toggleDropdown}>
                      Mint <FaChevronDown />
                    </button>
                    {showDropdown && (
                      <div className="dropdown-content" style={{ overflow: 'hidden' }}>
                        <a href="/mint-1">Bollywood Star Themes</a>
                        <a href="/mint-2">Indian Women Themes</a>
                        <a href="/mint-3">Indian Goddesses Themes</a>
                        <a href="/mint-star">Star Wars Themes</a>
                        <a href="/mint-space">Space Suite Themes</a>
                        <a href="/mint-senorita">Spanish Senorita Themes</a>
                      </div>
                    )}
                  </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
            </ul>  
        </div>
        <div className="mobile_menu_social_links">
          {/* <a href="# ">
            <img src={openseaIcon} alt="bithu social icon" />
          </a> */}
          {/* <a href="# ">
            <FaTwitter />
          </a>
          <a href="# ">
            <FaDiscord />
          </a> */}
        </div>
        <Button
          sm
          variant="hovered"
          className="connect_btn"
          onClick={() => walletModalHandle()}
        >
          <FaWallet /> Connect
        </Button>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
