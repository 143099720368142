import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import HomeV4 from "../pages/homeV4";
import HomeV5 from "../pages/homeV5";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import ComingSoon from "../pages/comingSoon";
import AboutPage from "../pages/about";
import RoadmapPage from "../pages/roadmap";
import Collections from "../pages/collections";
import ContactPage from "../pages/contact";
import MintPageOne from "../pages/mint-1";
import MintPageTwo from "../pages/mint-2";
import MintPageThree from "../pages/mint-3";
import MintPageseno from "../pages/mint-senorita";
import MintPagespa from "../pages/mint-space";
import MintPagesta from "../pages/mint-star";
import FAQPage from "../pages/faq";


function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV3 />}  />
     
      <Route path="/home-four" element={<HomeV3 />} exact />
     
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/post" element={<BlogDetails />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/about" element={<AboutPage />} />
      {/* <Route path="/faq" element={<FAQPage />} /> */}
      <Route path="/roadmap" element={<RoadmapPage />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/mint-1" element={<MintPageOne />} />
      <Route path="/mint-2" element={<MintPageTwo />} />
      <Route path="/mint-3" element={<MintPageThree />} />
      <Route path="/mint-senorita" element={<MintPageseno />} />
      <Route path="/mint-space" element={<MintPagespa />} />
      <Route path="/mint-star" element={<MintPagesta />} />

    </Routes>
  );
}

export default App;
