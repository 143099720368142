import styled from "styled-components";
import AboutBG from "../../../../assets/images/nft/pexels-oleksandr-p-1007657.jpg";

const FAQStyleWrapper = styled.section`
  background: url(${AboutBG});
  padding-bottom: 266px;
  padding-top: 675px;
  background-size: cover;

  .faq-title {
    width: 70%;
    margin: 0 auto;
    color: white;
  }

  .bithu_faq_content {
    max-width: 770px;
    width: 100%;
    margin: auto;
    position: relative;
    font-color: #FFFFFF;

  }

  .bithu_faq_questions {
    margin-top: 57px;
    position: relative;
    .faq_bear_img {
      position: absolute;
      top: 80px;
      right: -163px;
      z-index: 2;
    }

    .faq_questions {
      position: relative;
      z-index: 1;

      .accordion__item + .accordion__item {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
  .accordion__item {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
  }
  .accordion__header {
    h5 {
      display: flex;
      align-items: center;
      color: #FFFF;
      height: 76px;
      padding: 0 29px;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 0px;
    }
    h5:hover {
      color: #FF5A5F; /* Change to desired hover color */
    }

    

  }

  .accordion__body {
    p {
      padding: 0px 48px 24px 29px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: #FFFFF;
      margin-bottom: 0px;
      max-width: 100%;
    }
  }

  .faq_bg_wattermark {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  @media only screen and (max-width: 991px) {
    .bithu_faq_questions {
      .faq_bear_img {
        display: none;
      }
    }

    .accordion__header {
      h5 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .accordion__header {
      h5 {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .accordion__body {
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .faq-title {
      width: 80% !important;
    }
    .accordion__header {
      h5 {
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .faq-title {
      h2 {
        font-size: 15px;
      }
      width: 100% !important;
    }
  }
`;

export default FAQStyleWrapper;
