import styled from "styled-components";

const Docum = styled.section`
  padding: 85px 0 140px;
  
.container {
  background-color: #121212;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  padding: 20px;
  line-height: 1.6;
}

.title {
  font-size: 2.5em;
  color: #f39c12;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 30px;
}

.subtitle {
  font-size: 1.8em;
  color: #e67e22;
  margin-bottom: 10px;
}

.list {
  list-style-type: none;
  padding: 0;
}

.list-item {
  background: #333333;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.button {
  background-color: #e67e22;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #d35400;
}
`;

export default Docum;