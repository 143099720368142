import character1 from "../../images/nft/p1.png";
import character2 from "../../images/nft/p2.png";
import character3 from "../../images/nft/p3.png";
import character4 from "../../images/nft/p4.png";
import character5 from "../../images/nft/p5.png";
import character6 from "../../images/nft/p6.png";
import character7 from "../../images/nft/p7.png";
import character8 from "../../images/nft/p8.png";
import character9 from "../../images/nft/p9.png";
import character10 from "../../images/nft/p10.png";
import character11 from "../../images/nft/p11.png";
import character12 from "../../images/nft/p12.png";
import character13 from "../../images/nft/p13.png";
import character14 from "../../images/nft/p14.png";
import character15 from "../../images/nft/p15.png";
import character16 from "../../images/nft/p16.png";
import character17 from "../../images/nft/p17.png";
import character18 from "../../images/nft/p18.png";
import character19 from "../../images/nft/p19.png";
import character20 from "../../images/nft/p20.png";
import character21 from "../../images/nft/p21.png";
import character22 from "../../images/nft/p22.png";
const data = [
  character1,
  character22,
  character13,
  character4,
  character18,
  character5,
  character19,
  character6,
  character16,
  character5,
  character12,
  character20,
  character7,
  character5,
  character14,
  character8,
  character17,
  character9,
  character10,
  character11,
  character3,
  character12,
  character2,
  character15,
  character21,

];

export default data;
