import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/p19.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "Dana Priyanka Hammond, a renowned author and advocate, is embarking on a deeply personal quest to unravel the enigmatic fragments of her past. Known for her captivating storytelling and expressive narratives, Dana has touched the souls of readers across the globe. Yet, beneath her literary triumphs lies an unfinished chapter of her life – the quest to reconnect with her birth family. In her pursuit, Dana is reaching out to the global community for financial support by selling her unique art NFTs, driven by compelling and heartfelt reasons.",
  aboutDescription2:
    "Dana's story begins in India, where she was tragically separated from her birth family at the young age of four, a victim of child trafficking. Despite her remarkable strength and accomplishments, the mystery of her origins loomed over her life, leaving her with a deep desire to reunite with her family. Dana's journey to find her roots is a personal pursuit and a profound quest for identity and closure.",
    aboutDescription6:
    "To achieve this, she seeks funds to support her in-depth research and multiple trips to India, where she hopes to piece together her past and reconnect with her long-lost family. This journey of rediscovery necessitates significant resources, including travel expenses, hiring local investigators, and access to archival records. By purchasing her art NFTs, supporters can play a vital role in helping Dana bridge the gap between her past and present, ultimately enabling her to uncover her origins and complete the missing pieces of her life's puzzle.",
    aboutDescription3:  
    'Supply: 3,333',
  aboutDescription4:  
    'Price: Near Free ',
  aboutDescription5:   
  'Mint Prizes: Yaka Voyager, Seiyans, Seipes NFTs',
  artists: [
  
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Dana Priyanka Hammond",
      role: "Artist",
    },
  ],
};

export default data;
