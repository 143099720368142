import checkIcon from "../../images/blog/arrow.png";
import thumb1 from "../../images/blog/0.png";
import thumb2 from "../../images/blog/i.png"; 
import thumb3 from "../../images/blog/sp.png";
import thumb4 from "../../images/blog/g.png";
import thumb5 from "../../images/blog/st.png";
import thumb6 from "../../images/blog/S.png";

const data = [
  {
    progressIcon: checkIcon,
    progress: "1",
    title: "Bollywood Star Themes",
    text: "Vibrant and glamorous representations of Bollywood stars, capturing the essence of Indian cinema",
    thumb: thumb1,
  },
  {
    progressIcon: checkIcon,
    progress: "2",
    title: "Indian Women Themes",
    text: "Diverse and influential depictions of Indian women showcasing their strength, beauty, and cultural significance",
    thumb: thumb2,
  },
  {
    progressIcon: checkIcon,
    progress: "3",
    title: "Spanish Senorita Themes",
    text: "Stunning portrayals of Spanish señoritas, celebrating the vibrant Spanish culture",
    thumb: thumb3,
  },
  {
    progressIcon: checkIcon,
    progress: "4",
    title: "Indian Goddesses Themes",
    text: "Artistic renditions of goddesses with an Indian cultural influence, symbolizing various aspects of divinity, power, and grace",
    thumb: thumb4,
  },
  {
    progressIcon: checkIcon,
    progress: "5",
    title: "Star Wars Themes",
    text: "For sci-fi enthusiasts, this theme blends Indian cultural elements with the iconic Star Wars universe, creating a unique fusion of themes",
    thumb: thumb5,
  },
  {
    progressIcon: checkIcon,
    progress: "6",
    title: "Space Suite Themes",
    text: "Imaginative and celestial representations that merge Indian motifs with cosmic imagery",
    thumb: thumb6,
  },
];

export default data;
