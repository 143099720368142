import FooterBottom from "./footerBottom/FooterBottom";

import data from "../../../../assets/data/footer/footerDataV3";
import footerLogo from "../../../../assets/images/logo.png";
import footerLink from "../../../../assets/images/linked.png";
import footeryou from "../../../../assets/images/you.png";
import footerinsta from "../../../../assets/images/instuu.png";
import fb from "../../../../assets/images/fb.png";
import btnArrow from "../../../../assets/images/blog/arrow-icon.png";
import logo from "../../../../assets/images/logggo.png";

import FooterStyleWrapper from "./Footer.style";

const Footer = () => {
  return (
    <FooterStyleWrapper>
      <div className="top-footer-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer_image">
                <a href="# ">
                  <img src={logo} alt="bithu nft footer logo" />
                </a>
               
              </div>
            </div>

            {/* link widgets  */}
            {data?.map((menu, i) => (
              <div key={i} className="col-sm-6 col-md-3 col-lg-2 link-widgets">
                <div className="footer-menu">
                  <h5 className="menu-title">{menu.widgetName}</h5>
                  <ul className="menu-list">
                    {menu.items?.map((item, i) => (
                      <li key={i}>
                        <a href={item.url}>{item.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
            <div className="col-md-6 col-lg-4">
              <div className="footer-menu2 footer_newsletter">
                <h5 className="menu-title">Social media Links</h5>
                <div className="form-box">
                  <div className="container">
                    <div className="social-links">
                      <a href="https://www.linkedin.com/in/dana-priyanka-hammond?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                        <img src={footerLink} className="footer-img linkedin-img" />
                      </a>
                      <a href="https://youtube.com/@danapriyankahammond2612?si=VgzuShk7tF1OKY46" target="_blank" rel="noopener noreferrer">
                        <img src={footeryou} className="footer-img youtube-img" />
                      </a>
                      <a href="https://www.instagram.com/danapriyankahammond?igsh=MXIyeXVrYXZzYzdkZg==" target="_blank" rel="noopener noreferrer">
                        <img src={footerinsta} className="footer-img instagram-img" />
                      </a>
                      <a href="https://www.facebook.com/dana.priyanka.32" target="_blank" rel="noopener noreferrer">
                        <img src={fb} className="footer-img facebook-img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom  */}
      <FooterBottom />
    </FooterStyleWrapper>
  );
};

export default Footer;
