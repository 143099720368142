const data = [ 
    {
      widgetName: "Site map",
      items: [
        {
          title: "Home",
          url: "/",
        },
        {
          title: "About",
          url: "#about",
        },
        {
          title: "Our Goals",
          url: "#roadmap",
        },
        {
          title: "FAQ",
          url: "#faq",
        },
        
      ],
    },
   
]

export default data;
